import "./Order.css";
import { CartContext } from "../contexts/CartContext";
import { useContext } from "react";

function Order({ paints }) {
    const { cartItems } = useContext(CartContext);

    return (
        <div className='order'>
            <div className="order-container">
                <div className="order-number-date">
                    <div>رقم التتبع : {'xxxxxxxx'}</div>
                    <div>تاريخ الطلب : {new Date(paints.created_at).toLocaleDateString()}</div>
                </div>
                <div className="orders">
                    تفاصيل الطلب :
                    <div className="order-info">
                    {cartItems.map((item, i) => (
                        <img src={item.paint.image1} alt="" />
                    ))}
                    </div>
                </div>
                <div>المبلغ الاجمالي : {paints.amount} ر.س </div>
                <div className="order-status">
                حالة الطلب : 
                <span className="status">{paints.status}</span>
                </div>
            </div>
        </div>
    )
}

export default Order;