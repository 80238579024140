import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLoading } from "./contexts/LoadingContext"; 


const initialValues = {
    current_password: "",
    new_password: "",
    new_password2: "",
};

const validationSchema = Yup.object({
    current_password: Yup.string().required("هذا الحقل مطلوب"),

    new_password: Yup.string()
        .required("هذا الحقل مطلوب")
        .min(8, "كلمة المرور هذه قصيرة جدًا. يجب أن تكون 8 على الأقل")
        .matches(/[0-9]/, "يجب ان تحتوي كلمة المرور على رقم")
        .matches(/[a-z]/, "يجب ان تحتوي كلمة المرور على حرف صغير")
        .matches(/[A-Z]/, "يجب ان تحتوي كلمة المرور على حرف كبير")
        .matches(
            /[^\w]/,
            "يجب ان تحتوي كلمة المرور على رمز واحد مميز على الاقل",
        ),

    new_password2: Yup.string()
        .required("هذا الحقل مطلوب")
        .oneOf([Yup.ref("new_password"), null], "كلمة المرور غير متطابقة"),
});

function PasswordChange() {
    const [showForm, setShowForm] = useState(false);
    const { authToken, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();

    const onSubmit = async (values, { setErrors }) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                "auth/users/set_password/",
                values,
                {
                    headers: { Authorization: `Token ${authToken}` },
                },
            );
            logout();
            navigate('/login')
            console.log("Password change request sent", response);
        } catch (error) {
            setErrors(error.response.data);
            console.error("Error sending password change request", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="password-change section">
            <div className="current-data">
                <h3>تغيير كلمة المرور :</h3>
                <h3>**********</h3>
                {!showForm && (
                    <button onClick={() => setShowForm(true)} className="change-btn">
                        تعديل
                    </button>
                )}
            </div>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <Form noValidate>
                        <div className="inp-container">
                            <div className="form-group">
                                <label>كلمة المرور الحالية</label>
                                <Field
                                    type="password"
                                    id="current_password"
                                    name="current_password"
                                />
                                <ErrorMessage name="current_password">
                                    {(msg) => (
                                        <div className="errorMessage">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>

                            <div className="form-group">
                                <label>كلمة المرور الجديدة</label>
                                <Field
                                    type="password"
                                    id="new_password"
                                    name="new_password"
                                />
                                <ErrorMessage name="new_password">
                                    {(msg) => (
                                        <div className="errorMessage">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>

                            <div className="form-group">
                                <label>إعادة إدخال كلمة المرور الجديدة</label>
                                <Field
                                    type="password"
                                    id="new_password2"
                                    name="new_password2"
                                />
                                <ErrorMessage name="new_password2">
                                    {(msg) => (
                                        <div className="errorMessage">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                        </div>
                        <div className="btns-container">
                            <input type="submit" value="حفظ" />
                            <button
                                type="button"
                                className="cancle-btn"
                                onClick={() => setShowForm(false)}
                            >
                                إلغاء
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

export default PasswordChange;
