import React from "react";
import { useLoading } from "../contexts/LoadingContext";
import './Loader.css';

const Loader = () => {
    const { isLoading } = useLoading();

    return (
        <>
            {isLoading && (
                <div className="loader-container">
                    <div className="logo-icon">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1816.000000 789.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,789.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                <path d="M15369 7507 c-1220 -1138 -2040 -2626 -2374 -4307 -43 -215 -65 -291
-110 -380 -48 -95 -92 -131 -214 -180 -57 -23 -147 -61 -200 -85 -170 -78
-285 -93 -375 -50 -76 36 -93 107 -56 229 45 144 227 349 377 423 76 37 79 38
193 38 175 1 175 0 -177 186 -127 67 -160 72 -260 41 -156 -49 -324 -213 -385
-377 -18 -48 -23 -80 -22 -160 1 -88 4 -110 33 -181 67 -172 243 -393 375
-473 192 -115 348 -118 563 -10 98 49 123 54 123 25 0 -29 85 -181 139 -249
61 -76 218 -231 265 -262 46 -30 50 -6 11 65 -84 156 -128 364 -128 605 -1
627 292 1588 832 2734 342 726 976 1753 1525 2474 16 21 26 37 24 37 -3 0 -74
-65 -159 -143z" />
                                <path d="M15486 7342 c-72 -125 -232 -398 -356 -607 -385 -645 -524 -887 -750
-1305 -146 -269 -380 -736 -479 -955 -305 -675 -473 -1304 -513 -1911 -13
-204 0 -551 27 -728 46 -296 156 -587 304 -807 158 -234 295 -370 446 -444 86
-41 254 -105 278 -105 9 0 53 18 97 40 103 52 309 122 452 154 240 54 403 75
883 116 582 49 813 85 1052 165 389 130 637 338 808 679 73 144 87 191 23 76
-59 -107 -154 -198 -260 -249 -83 -40 -203 -77 -192 -60 3 5 13 9 22 9 13 0
14 2 3 9 -11 7 -11 9 0 14 8 3 9 6 2 6 -6 1 -9 9 -6 20 3 10 0 22 -7 26 -8 5
-8 10 1 21 9 11 8 14 -2 14 -11 0 -11 2 1 10 13 9 13 10 0 11 -10 0 -8 3 5 9
l20 9 -20 1 -20 1 20 9 c13 6 15 9 5 9 -13 1 -13 2 0 11 8 5 10 10 3 10 -6 0
-9 8 -6 19 3 10 0 22 -7 26 -8 5 -8 10 1 21 9 11 8 14 -2 14 -11 0 -11 2 1 10
13 9 13 10 0 11 -10 0 -8 3 5 9 l20 9 -20 1 -20 1 20 9 c13 6 15 9 5 9 -13 1
-13 2 0 11 8 5 10 10 3 10 -6 0 -9 8 -6 19 3 10 0 22 -7 26 -8 5 -8 10 1 21 9
11 8 14 -2 14 -11 0 -11 2 1 10 13 9 13 10 0 11 -10 0 -8 3 5 9 l20 9 -20 1
-20 1 20 9 c13 6 15 9 5 9 -13 1 -13 2 0 11 8 5 10 10 3 10 -6 0 -9 8 -6 19 3
10 0 22 -7 26 -8 5 -8 10 1 21 9 11 8 14 -2 14 -11 0 -11 2 1 10 13 9 13 10 0
11 -10 0 -8 3 5 9 l20 9 -20 1 -20 1 20 9 c13 6 15 9 5 9 -13 1 -13 2 0 11 8
5 10 10 3 10 -6 0 -9 9 -6 23 3 12 1 18 -4 12 -4 -5 -15 -11 -23 -11 -8 -1
-78 -20 -155 -42 -77 -21 -176 -47 -219 -57 -75 -16 -82 -20 -129 -72 -68 -74
-220 -200 -342 -282 -55 -38 -293 -174 -530 -303 -1599 -874 -1450 -794 -1440
-776 63 108 495 708 834 1158 193 257 497 627 904 1103 l289 338 -177 352
c-98 194 -181 355 -184 359 -4 5 -44 -36 -88 -91 -45 -54 -212 -251 -370 -437
-158 -187 -331 -393 -384 -459 -331 -413 -703 -973 -969 -1458 -41 -75 -76
-130 -78 -122 -2 8 15 76 38 152 168 555 404 1056 717 1517 161 237 324 448
819 1056 273 335 521 652 543 694 11 20 -17 68 -325 542 -185 285 -344 531
-353 546 -9 16 -19 28 -22 28 -26 0 -1037 -1650 -1217 -1985 -271 -506 -443
-1023 -497 -1495 -30 -270 -16 -629 35 -865 75 -343 238 -691 422 -899 36 -41
39 -50 33 -82 -3 -19 -11 -34 -17 -32 -6 2 -87 125 -180 274 -296 472 -406
736 -464 1104 -22 138 -25 433 -5 555 91 579 425 1383 978 2354 174 307 527
873 947 1521 69 107 126 204 126 215 0 21 -187 1021 -195 1043 -12 32 -39 -5
-159 -216z m1789 -5272 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16
-10z m1 -42 c0 -10 -1 -18 -3 -18 -1 0 -7 8 -12 18 -7 13 -6 19 2 19 7 0 13
-9 13 -19z m-1 -118 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m1 -42 c0 -10 -1 -18 -3 -18 -1 0 -7 8 -12 18 -7 13 -6 19 2 19 7 0 13 -9 13
-19z m-1 -118 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m1 -42
c0 -10 -1 -18 -3 -18 -1 0 -7 8 -12 18 -7 13 -6 19 2 19 7 0 13 -9 13 -19z
m-1 -118 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m1 -42 c0
-10 -1 -18 -3 -18 -1 0 -7 8 -12 18 -7 13 -6 19 2 19 7 0 13 -9 13 -19z m-1
-118 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m-2 -37 c-3 -7
-8 -13 -12 -13 -4 0 -8 6 -8 13 0 8 6 14 13 14 7 0 10 -6 7 -14z" />
                                <path d="M16034 6348 c-41 -43 -116 -141 -208 -271 -37 -52 -46 -73 -40 -87
15 -30 709 -1080 714 -1080 22 0 270 450 270 489 0 49 -251 452 -412 664 -118
154 -262 326 -273 327 -6 0 -29 -19 -51 -42z" />
                                <path d="M2347 6214 c-4 -4 -7 -549 -7 -1211 l0 -1203 208 -1 c114 -1 863 -1
1664 -1 1603 0 1511 -3 1609 61 48 32 118 118 152 188 12 24 24 43 27 43 3 0
20 -31 37 -68 59 -127 167 -203 312 -221 102 -13 483 -3 546 14 100 28 186
110 252 239 l25 50 24 -58 c57 -142 168 -227 315 -244 35 -4 452 -6 927 -4
l862 3 0 442 c0 501 -3 529 -71 665 -70 142 -176 230 -335 278 -78 24 -253 24
-343 0 -193 -52 -369 -195 -454 -369 -114 -235 -96 -515 46 -700 21 -26 37
-49 37 -52 0 -3 -145 -5 -322 -5 -341 0 -415 8 -494 48 -93 47 -154 140 -173
262 -6 36 -11 208 -11 383 l0 317 -109 0 -109 0 -4 -417 c-5 -451 -7 -474 -61
-531 -49 -53 -67 -57 -295 -60 -132 -2 -239 1 -280 8 -176 30 -274 128 -301
300 -6 36 -11 208 -11 383 l0 317 -109 0 -109 0 -4 -417 c-5 -451 -7 -474 -61
-531 -57 -61 67 -57 -1619 -60 l-1538 -2 -2 1077 -3 1078 -106 3 c-58 1 -108
-1 -112 -4z m6526 -1302 c81 -40 142 -107 174 -189 21 -55 22 -73 22 -360 l1
-303 -200 0 c-228 0 -274 7 -385 60 -162 77 -235 196 -235 380 0 203 111 362
299 427 55 20 82 23 162 21 84 -3 103 -7 162 -36z" />
                                <path d="M9847 6213 c-7 -7 -10 -2407 -3 -2414 2 -2 52 -3 110 -1 l106 3 0
1210 0 1209 -103 0 c-57 0 -107 -3 -110 -7z" />
                                <path d="M5863 5873 c-59 -12 -112 -105 -99 -175 27 -142 198 -171 252 -42 49
117 -38 240 -153 217z" />
                                <path d="M6790 5862 c-96 -48 -110 -194 -24 -261 89 -70 206 -2 207 122 1 66
-24 111 -78 137 -50 24 -61 25 -105 2z" />
                                <path d="M7240 5863 c-110 -56 -108 -222 3 -277 124 -60 235 101 156 229 -34
57 -101 77 -159 48z" />
                                <path d="M8410 5863 c-110 -56 -108 -222 3 -277 124 -60 235 101 156 229 -34
57 -101 77 -159 48z" />
                                <path d="M8876 5870 c-60 -19 -96 -73 -96 -148 0 -154 188 -206 251 -69 23 52
24 85 3 137 -26 65 -94 99 -158 80z" />
                                <path d="M16854 4769 c-60 -79 -142 -184 -181 -233 l-73 -89 29 -51 c16 -28
91 -166 166 -306 75 -140 141 -259 145 -264 11 -11 63 153 85 264 23 118 30
342 16 470 -13 113 -58 330 -71 343 -4 5 -57 -56 -116 -134z" />
                                <path d="M1435 4854 c-11 -2 -45 -9 -75 -15 -171 -34 -303 -181 -329 -365 -21
-145 25 -276 137 -396 l45 -48 -167 0 -166 0 0 -115 0 -115 470 0 470 0 0 113
0 114 -117 6 c-188 10 -295 49 -377 137 -78 84 -108 177 -87 268 30 122 175
188 396 180 l90 -3 23 109 c12 60 21 110 19 112 -16 17 -269 30 -332 18z" />
                                <path d="M16355 4148 c-49 -61 -91 -114 -93 -119 -5 -13 379 -689 389 -684 13
9 159 212 159 222 0 18 -345 687 -355 689 -5 1 -50 -47 -100 -108z" />
                                <path d="M9973 3490 c-162 -32 -242 -196 -171 -350 13 -30 40 -70 58 -87 l34
-33 -102 0 -102 0 0 -55 0 -55 230 0 230 0 0 55 0 55 -43 0 c-152 0 -269 108
-254 236 9 83 83 133 198 134 73 0 79 5 79 65 0 28 -4 33 -31 39 -39 7 -71 6
-126 -4z" />
                                <path d="M2591 2074 c-239 -64 -391 -302 -353 -551 29 -190 157 -339 342 -399
46 -15 99 -18 325 -19 149 -2 291 0 318 2 l47 6 0 43 0 43 -141 3 -141 3 45
33 c123 88 193 271 167 438 -30 194 -170 350 -357 399 -71 19 -181 18 -252 -1z
m252 -111 c56 -17 134 -73 176 -127 152 -191 69 -508 -157 -597 -80 -32 -204
-32 -284 0 -74 29 -147 95 -186 168 -167 315 114 662 451 556z" />
                                <path d="M1002 1588 l3 -482 36 -2 c20 -1 41 1 47 5 9 5 12 127 10 482 l-3
474 -48 3 -47 3 2 -483z" />
                                <path d="M4264 2056 c-3 -8 -4 -31 -2 -52 l3 -39 158 -3 157 -3 0 -423 c0
-232 2 -425 4 -427 3 -2 25 -3 50 -1 l46 4 0 424 0 424 161 0 160 0 -3 53 -3
52 -363 3 c-304 2 -363 0 -368 -12z" />
                                <path d="M6090 1591 l0 -480 23 -3 c12 -2 34 -1 50 1 l27 3 -2 477 -3 476 -47
3 -48 3 0 -480z" />
                                <path d="M7390 1591 c0 -532 -5 -498 66 -485 l34 6 0 394 c0 217 3 394 8 394
4 0 131 -178 282 -395 251 -361 277 -395 307 -401 18 -3 49 -3 68 0 l35 7 0
480 0 480 -47 -3 -48 -3 -3 -397 c-1 -219 -6 -398 -10 -398 -9 1 18 -37 -302
423 l-263 377 -63 0 -64 0 0 -479z" />
                                <path d="M9697 2063 c-7 -11 -417 -943 -417 -947 0 -2 15 -7 34 -10 68 -13 77
-5 128 117 l47 112 248 3 248 2 39 -97 c57 -143 56 -143 117 -138 27 1 52 5
54 7 4 4 -384 922 -400 947 -7 12 -91 15 -98 4z m154 -395 l87 -218 -199 0
c-109 0 -199 2 -199 4 0 5 180 428 192 450 10 19 11 19 21 0 6 -10 50 -116 98
-236z" />
                            </g>
                        </svg>
                    </div>
                </div>
            )}
        </>
    );
};

export default Loader;
