import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import './Payment.css';
import logo from '../../assets/images/logo-2.png';
import title from '../../assets/images/title-2.png';
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { useLoading } from '../contexts/LoadingContext';


const stripePromise = loadStripe('pk_test_51PgmyLH0ol5JS6zjatFfWasugSXa0ux5gzNnnCKHu3pVli2Hi8eqM0iDOFCg4efBv5YHTxqrbGndqGEp8GF3am2G00lG3p955U');

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { authToken } = useContext(AuthContext);
    const [error, setError] = useState('');
    const { getCartTotal, cartItems, clearCart } = useContext(CartContext);
    const { setIsLoading } = useLoading();


    const handlePayment = async () => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post('api/payments', {
                amount: getCartTotal(),
                stripe_token: paymentMethod.id,
            }, {
                headers: {
                    Authorization: `Token ${authToken}`
                },
            });

            if (response.data.error) {
                throw new Error(response.data.error);
            }

            cartItems.map((item, i) => (clearCart(item.id)))
            alert('Payment Successful: ' + response.data.payment_id);
        } catch (error) {
            setError('Payment Failed: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const cardElementOptions = {
        hidePostalCode: true, // Hide the ZIP code field
    };

    return (
        <div className='payment'>
            <div className='logo'>
                <Link to="/">
                    <img src={logo} alt="" />
                    <img src={title} alt="" />
                </Link>
            </div>
            <div className="payment-form-container">
                <h2>طريقة الدفع</h2>
                <div style={{ 'direction': 'rtl', 'marginBottom': '12px' }}>
                    <span>المجموع  :  </span>
                    <span>{getCartTotal()} ر.س</span>
                </div>
                <CardElement options={cardElementOptions} className="card-element" />
                <button onClick={handlePayment} disabled={!stripe}>
                    إكمال عملية الدفع
                </button>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

const StripePaymentForm = () => (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
);

export default StripePaymentForm;
