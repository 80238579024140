import React, { useState, useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLoading } from "./contexts/LoadingContext";

const validationSchema = Yup.object({
    telephone: Yup.string()
        .matches(/^[0-9]+$/, "يجب أن يحتوي هذا الحقل على أرقام فقط")
        .min(10, "يجب أن تحتوي هذه الخانة على الأقل 10 أرقام")
        .required("هذا الحقل مطلوب"),
});

function TelephoneChange({telephone, updateUserData}) {
    const [showForm, setShowForm] = useState(false);
    const { authToken } = useContext(AuthContext);
    const { setIsLoading } = useLoading();

    const initialValues = {
        telephone: telephone || "",
    };

    const onSubmit = async (values, { setErrors }) => {
        setIsLoading(true);
        try {
            const response = await axios.patch(
                "auth/users/me/",
                values,
                {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                },
            );
            updateUserData(response.data);
            console.log("Telephone change request sent", response);
        } catch (error) {
            setErrors(error.response.data);
            console.error("Error sending Telephone change request", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="telephone-change section">
            <div className="current-data">
                <h3>تغيير رقم الهاتف :</h3>
                <h3>{telephone}</h3>
                {!showForm && (
                    <button
                        onClick={() => setShowForm(true)}
                        className="change-btn"
                    >
                        تعديل
                    </button>
                )}
            </div>

            {showForm && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    <Form noValidate>
                        <div className="inp-container center">
                            <div className="form-group">
                                <label>رقم الهاتف</label>
                                <Field
                                    type="text"
                                    id="telephone"
                                    name="telephone"
                                />
                                <ErrorMessage name="telephone">
                                    {(msg) => (
                                        <div className="errorMessage">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                        </div>
                        <div className="btns-container">
                            <input type="submit" value="حفظ" />
                            <button
                                type="button"
                                className="cancle-btn"
                                onClick={() => setShowForm(false)}
                            >
                                إلغاء
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

export default TelephoneChange;
