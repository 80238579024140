import React, { useState, useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLoading } from "./contexts/LoadingContext";


const validationSchema = Yup.object({
    username: Yup.string().required("هذا الحقل مطلوب"),
});

function UsernameChange({ username, updateUserData }) {
    const [showForm, setShowForm] = useState(false);
    const { authToken } = useContext(AuthContext);
    const { setIsLoading } = useLoading();

    const initialValues = {
        username: username || "",
    };


    const onSubmit = async (values, { setErrors }) => {
        setIsLoading(true);
        try {
            const response = await axios.patch("auth/users/me/", values, {
                headers: { Authorization: `Token ${authToken}` },
            });
            updateUserData(response.data);
            console.log("username change request sent", response);
        } catch (error) {
            setErrors(error.response.data);
            console.error("Error sending username change request", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="username-change section">
            <div className="current-data">
                <h3> أسم المستخدم:</h3>
                <h3>{username}</h3>
                {!showForm && (
                    <button onClick={() => setShowForm(true)} className="change-btn">
                        تعديل
                    </button>
                )}
            </div>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    <Form noValidate>
                        <div className="inp-container center">
                            <div className="form-group">
                                <label>أسم المستخدم الجديد</label>
                                <Field
                                    type="text"
                                    id="username"
                                    name="username"
                                />
                                <ErrorMessage name="username">
                                    {(msg) => (
                                        <div className="errorMessage">
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                        </div>

                        <div className="btns-container">
                            <input type="submit" value="حفظ" />
                            <button
                                type="button"
                                className="cancle-btn"
                                onClick={() => setShowForm(false)}
                            >
                                إلغاء
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

export default UsernameChange;
