import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLoading } from "./contexts/LoadingContext"; 


const validationSchema = Yup.object({
    new_email: Yup.string().email("صيغة الايميل غير صحيحه").required("هذا الحقل مطلوب"),
    current_password: Yup.string().required("هذا الحقل مطلوب"),
});


function EmailChange({ email }) {
    const [showForm, setShowForm] = useState(false);
    const { authToken, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();

    const initialValues = {
        new_email: email || "",
        current_password: "",
    };

    const onSubmit = async (values, { setErrors }) => {
        setIsLoading(true);
        try {
            const response = await axios.post("auth/users/set_email/", values, {
                headers: { Authorization: `Token ${authToken}` },
            });
            logout();
            navigate('/login')
            console.log("Email change request sent", response);
        } catch (error) {
            console.log(error)
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="email-change section">
            <div className="current-data">
                <h3>البريد الالكتروني:</h3>
                <h3>{email}</h3>
                {!showForm && (
                    <button onClick={() => setShowForm(true)} className="change-btn">
                        تعديل
                    </button>
                )}
            </div>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    <Form noValidate>
                        <div className="inp-container">
                            <div className="form-group">
                                <label>البريد الإلكتروني الجديد</label>
                                <Field
                                    type="email"
                                    id="new_email"
                                    name="new_email"
                                />
                                <ErrorMessage name="new_email">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>
                            <div className="form-group">
                                <label>كلمة مرور اقتناء الحالية</label>
                                <Field
                                    type="password"
                                    id="current_password"
                                    name="current_password"
                                />
                                <ErrorMessage name="current_password">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>
                        </div>

                        <div className="btns-container">
                            <input type="submit" value="حفظ" />
                            <button
                                type="button"
                                className="cancle-btn"
                                onClick={() => setShowForm(false)}
                            >
                                إلغاء
                            </button>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}

export default EmailChange;