import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import PersonalInfo from "../PersonalInfo";
import EmailChange from "../EmailChange";
import UsernameChange from "../UsernameChange";
import PasswordChange from "../PasswordChange";
import ProfileImage from "../ProfileImage";
import TelephoneChange from "../TelephoneChange";
import "./AccountSettings.css";
import { useLoading } from '../contexts/LoadingContext';

function AccountSettings() {
    const [userData, setUserData] = useState({});
    const { userId, authToken } = useContext(AuthContext);
    const { setIsLoading } = useLoading();

    useEffect(() => {
        setIsLoading(true);
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(
                    `api/users-profiles/${userId}`,
                    {
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    },
                );
                setUserData(response.data);
                console.log("User data fetched:", response.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUserProfile();
    }, [userId, authToken, setIsLoading]);

    const updateUserData = (updatedData) => {
        setUserData((prevUserData) => ({
            ...prevUserData,
            ...updatedData,
        }));
    };

    return (
        <div className='account-settings'>
            <ProfileImage showText={true} />
            <hr />
            <PersonalInfo
                first_name={userData.first_name}
                last_name={userData.last_name}
                country={userData.country}
                city={userData.city}
                birth_date={userData.birth_date}
                updateUserData={updateUserData} />
            <hr />
            <UsernameChange
                username={userData.username}
                updateUserData={updateUserData} />
            <hr />
            <EmailChange
                email={userData.email}
                updateUserData={updateUserData} />
            <hr />
            <PasswordChange />
            <hr />
            <TelephoneChange
                telephone={userData.telephone}
                updateUserData={updateUserData} />
        </div>
    )
}

export default AccountSettings;