import React, { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useLoading } from "./contexts/LoadingContext"; 

function PersonalInfo({ first_name, last_name, country, city, birth_date, updateUserData }) {
    const { userId, authToken } = useContext(AuthContext);
    const { setIsLoading } = useLoading();

    const initialValues = {
        first_name: first_name || "",
        last_name: last_name || "",
        country: country || "",
        city: city || "",
        birth_date: birth_date || "",
    };

    const onSubmit = async (values, { setErrors }) => {
        setIsLoading(true);
        try {
            if (values.birth_date === '') {
                values.birth_date = null;
            }
            const response = await axios.patch(
                `api/users-profiles/${userId}`,
                values,
                {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                },
            );
            updateUserData(response.data);
            console.log("Personal Information Updated Successfully:", response.data);
        } catch (error) {
            setErrors(error.response.data);
            console.error("Error updating Personal Information:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="personal-info section">
            <h3>البيانات الشخصية :</h3>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            >
                <Form noValidate>
                    <div className="inp-container">
                        <div className="form-group">
                            <label>الاسم الأول</label>
                            <Field
                                type="text"
                                id="first_name"
                                name="first_name"
                            />
                            <ErrorMessage name="first_name">
                                {msg => <div className="errorMessage">{msg}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="form-group">
                            <label>الاسم الأخير</label>
                            <Field
                                type="text"
                                id="last_name"
                                name="last_name"
                            />
                            <ErrorMessage name="last_name">
                                {msg => <div className="errorMessage">{msg}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="form-group">
                            <label>الدولة</label>
                            <Field type="text" id="country" name="country" />
                            <ErrorMessage name="country">
                                {msg => <div className="errorMessage">{msg}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="form-group">
                            <label>المدينة</label>
                            <Field type="text" id="city" name="city" />
                            <ErrorMessage name="city">
                                {msg => <div className="errorMessage">{msg}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="form-group">
                            <label>تاريخ الميلاد</label>
                            <Field
                                type="date"
                                id="birth_date"
                                name="birth_date"
                            />
                            <ErrorMessage name="birth_date">
                                {msg => <div className="errorMessage">{msg}</div>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="btns-container">
                        <input type="submit" value="حفظ التعديلات" />
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default PersonalInfo;
